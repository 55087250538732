  @media (max-width:600px){
    .categoryCardImage{
      height: 270px;
      width: 185px;
    }
    .listCardImage{
      height: 310px;
      width: 210px;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .detailpageButtons{
      color: #3D2E57;
      padding:4px;
      padding-left: 15px;
      padding-right: 15px;
      text-decoration: none;
      border: 1px solid black;
      font-size: 12px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-weight: 700;
    }
  }
   
  @media (min-width:0px) {
    .cardDetailPagePics{
      max-Width: 46px;
     max-Height: 70px,
    }
    }  
   
   
  @media (min-width:600px) {
    .cardDetailPagePics{
      max-Width: 46px;
     max-Height: 70px,
    }
    .listCardImage{
      max-width: 100%;
      max-height: 100%;
    }
  }
  @media (min-width:900px) {
    .cardDetailPagePics{
      max-Width: 56px;
     max-Height: 80px,
    }
  }
  @media (min-width:1200px) {
    .cardDetailPagePics{
      max-Width: 66px;
     max-Height: 100px,
    }
  }
  @media (min-width:1536px) {
    
  }