.link {
    display: flex !important;
    align-items: center !important;
    text-decoration: none !important;
    color: #2A2246 !important;
  }
  
  .link:hover {
    display: flex !important;
    align-items: center !important;
    text-decoration: none !important;
    color: #FF8D2A !important;
  }

 
@media (min-width:0px) {
  .userIcon { 
    width: 18px;
    height: 19px;
  }
  .logoSize {
    width: 170px;
  }
  .rightBox {
    width: 0px;
  } 
}
@media (min-width:600px) {
  .fontsize { 
    font-size: 10px;
    margin-right: 15px;
  }
  .userIcon { 
    width: 17px;
    height: 18px;
  }
  .logoSize {
    width: 170px;
  }
}
@media (min-width:900px) {
  .fontsize { 
    font-size: 15px;
    margin-right: 15px;
  }
  .userIcon { 
    width: 18px;
    height: 19px;
  }
  .logoSize {
    width: 232px;
  }
  .rightBox {
    width: 100%;
  }
}
@media (min-width:1200px) {
  .fontsize { 
    font-size: 18px;
    margin-right: 30px;
  }
  .userIcon { 
    width: 23px;
    height: 24px;
  }
  .logoSize {
    width: 265px;
  }

  .rightBox {
    width: 100%;
    height:100%;

  }
}
@media (min-width:1536px) {
  .fontsize { font-size: 20px;}
  .rightBox {
    width: 100%;
    height:100%;
  }
}